import { API_VERSION, DOMAIN } from './constants';
const API_PATH = `${DOMAIN}/ui/api/${API_VERSION}`;
export const WEBSOCKET_PATH = `${DOMAIN}/websocket`;

// Provision Key Management
const PROVISION_KEY_BASE_URL = `${API_PATH}/keys`;
export const DELETE_PROVISION_KEY = `${PROVISION_KEY_BASE_URL}/:apiKeyId`;

// Api Token Management
const API_TOKEN_BASE_URL = `${API_PATH}/tokens`;
export const ADD_API_TOKEN = `${API_TOKEN_BASE_URL}`;
export const GET_API_TOKENS = `${API_TOKEN_BASE_URL}/`;
export const GET_API_TOKEN_DETAIL = `${API_TOKEN_BASE_URL}/:apiTokenId`;
export const DELETE_API_TOKEN = `${API_TOKEN_BASE_URL}/:apiTokenId`;

// Application Management
const APPLICATION_BASE_URL = `${API_PATH}/applications`;
export const GET_APPLICATIONS_LIST = `${APPLICATION_BASE_URL}/`;
export const GET_APPLICATION_DETAIL = `${APPLICATION_BASE_URL}/:appId`;
export const GET_USER_PROFILE_SCHEMA = `${APPLICATION_BASE_URL}/:appId/schemas/user/default`;
export const UPDATE_DEFAULT_ACTION = `${APPLICATION_BASE_URL}/:appId/defaultAction`;
export const UPDATE_SSL = `${APPLICATION_BASE_URL}/:appId/ssl`;
export const GET_SSL = `${APPLICATION_BASE_URL}/:appId/ssl`;
export const GET_ATTRIBUTE_PASSES = `${APPLICATION_BASE_URL}/:appId/attribute-passes/`;
export const GET_IDPS_OF_APPLICATIONS = `${APPLICATION_BASE_URL}/:appId/IDPs/`;
export const GET_MFAS_OF_APPLICATIONS = `${APPLICATION_BASE_URL}/:appId/MFAs`;
export const UN_ATTACH_MFA = `${APPLICATION_BASE_URL}/:appId/MFAs`;
export const GET_RULES_OF_APPLICATION = `${APPLICATION_BASE_URL}/:appId/rules/`;
export const GET_APP_ATTRIBUTES = `${APPLICATION_BASE_URL}/:appId/attributes/`;
export const ADD_APP_ATTRIBUTE = `${APPLICATION_BASE_URL}/:appId/attributes`;
export const UPDATE_APP_ADVANCED_SETTING = `${APPLICATION_BASE_URL}/:appId/advance`;
export const GET_APP_ADVANCED_SETTING = `${APPLICATION_BASE_URL}/:appId/advance`;
export const UPDATE_APP_TEMPLATE = `${APPLICATION_BASE_URL}/:appId/template`;
export const GET_APP_TEMPLATE = `${APPLICATION_BASE_URL}/:appId/template`;
export const GET_APP_KERBEROS =`${APPLICATION_BASE_URL}/:appId/kerberos`;
export const UPDATE_APP_KERBEROS = `${APPLICATION_BASE_URL}/:appId/kerberos`;
export const ADD_RULE = `${APPLICATION_BASE_URL}/:appId/rules`;
export const DISABLE_APPLICATION = `${APPLICATION_BASE_URL}/:appId/disable`;
export const ENABLE_APPLICATION = `${APPLICATION_BASE_URL}/:appId/enable`;
export const GET_APP_PROFILE_SELECT = `${APPLICATION_BASE_URL}/:appId/profileselect`;
export const UPDATE_APP_PROFILE_SELECT = `${APPLICATION_BASE_URL}/:appId/profileselect`;


// Application Attribute Management
const ATTRIBUTE_BASE_URL = `${API_PATH}/attributes`;
export const DELETE_APP_ATTRIBUTE = `${ATTRIBUTE_BASE_URL}/:attributeId`;
export const UPDATE_APP_ATTRIBUTE = `${ATTRIBUTE_BASE_URL}/:attributeId`;
export const ADD_ATTRIBUTE_PASS = `${ATTRIBUTE_BASE_URL}/:attributeId/attribute-passes`;
export const GET_ATTRIBUTE_MAPPINGS = `${ATTRIBUTE_BASE_URL}/mappings/`;

// Attribute Pass Management
const ATTRIBUTE_PASS_BASE_URL = `${API_PATH}/attribute-passes`;
export const DELETE_ATTRIBUTE_PASS = `${ATTRIBUTE_PASS_BASE_URL}/:attributePassId`;
export const UPDATE_ATTRIBUTE_PASS = `${ATTRIBUTE_PASS_BASE_URL}/:attributePassId`;

// Clone Management
const CLONE_BASE_URL = `${API_PATH}/clone`;
export const COPY_APPLICATION = `${CLONE_BASE_URL}/application/:appId/to/:deploymentId`;
export const DUPLICATE_DEPLOYMENT = `${CLONE_BASE_URL}/deployment/:deploymentId`;

// Deployment Management
const DEPLOYMENT_BASE_URL = `${API_PATH}/deployments`;
export const ADD_DEPLOYMENT = `${DEPLOYMENT_BASE_URL}`;
export const GET_DEPLOYMENT_DETAIL = `${DEPLOYMENT_BASE_URL}/:deploymentId`;
export const GET_DEPLOYMENTS = `${DEPLOYMENT_BASE_URL}/`;
export const UPDATE_DEPLOYMENT = `${DEPLOYMENT_BASE_URL}/:deploymentId`;
export const UPDATE_DEPLOYMENT_STATUS = `${DEPLOYMENT_BASE_URL}/status`;
export const DELETE_DEPLOYMENT = `${DEPLOYMENT_BASE_URL}/:deploymentId`;
export const GET_APPLICATIONS_OF_DEPLOYMENT = `${DEPLOYMENT_BASE_URL}/:deploymentId/applications/search`;
export const ADD_APPLICATION = `${DEPLOYMENT_BASE_URL}/:deploymentId/applications`;
export const GET_PROVISION_KEYS_OF_DEPLOYMENT = `${DEPLOYMENT_BASE_URL}/:deploymentId/keys/`;
export const ADD_PROVISION_KEY = `${DEPLOYMENT_BASE_URL}/:deploymentId/keys`;
export const ADD_LDAP = `${DEPLOYMENT_BASE_URL}/:deploymentId/LDAPs`;
export const CREATE_TEST_CASE = `${DEPLOYMENT_BASE_URL}/:deploymentId/testcases`;
export const GET_DEPLOYMENT_TEMPLATE = `${DEPLOYMENT_BASE_URL}/:deploymentId/template`;
export const UPDATE_DEPLOYMENT_TEMPLATE = `${DEPLOYMENT_BASE_URL}/:deploymentId/template`;
export const GET_DEPLOYMENT_BRANDING =`${DEPLOYMENT_BASE_URL}/:deploymentId/branding`;
export const UPDATE_DEPLOYMENT_BRANDING =`${DEPLOYMENT_BASE_URL}/:deploymentId/branding`;
export const REFRESH_DEPLOYMENT_CONFIG = `${DEPLOYMENT_BASE_URL}/:deploymentId/configs/refresh`;
export const UPDATE_DEPLOYMENT_ADVANCED_SETTING = `${DEPLOYMENT_BASE_URL}/:deploymentId/advance`;
export const GET_DEPLOYMENT_ADVANCED_SETTING = `${DEPLOYMENT_BASE_URL}/:deploymentId/advance`;
export const DOWNLOAD_DAP_CONFIG = `${DEPLOYMENT_BASE_URL}/:deploymentId/cmdNConfig`;

// IdP Management
const IDP_BASE_URL = `${API_PATH}/IDPs`;
export const ADD_IDP = `${IDP_BASE_URL}`;
export const GET_IDPS = `${IDP_BASE_URL}/`;
export const GET_IDP_DETAIL = `${IDP_BASE_URL}/:idpId`;
export const DELETE_IDP = `${IDP_BASE_URL}/:idpId`;
export const UPDATE_IDP = `${IDP_BASE_URL}/:idpId`;
export const UN_ATTACH_IDP = `${IDP_BASE_URL}/:idpId/applications/:appId`;
export const ASSIGN_IDP = `${IDP_BASE_URL}/:idpId/applications/:appId`;
export const UPDATE_ATTRIBUTE_MAPPINGS = `${IDP_BASE_URL}/attribute-mappings`;
export const CREATE_AND_ASSIGN_IDP = `${IDP_BASE_URL}/to/applications/:appId`;

// MFA Management
const MFA_BASE_URL = `${API_PATH}/MFAs`;
export const ADD_MFA = `${MFA_BASE_URL}`;
export const GET_MFAS = `${MFA_BASE_URL}/`;
export const GET_MFA_DETAIL = `${MFA_BASE_URL}/:mfaId`;
export const DELETE_MFA = `${MFA_BASE_URL}/:mfaId`;
export const UPDATE_MFA = `${MFA_BASE_URL}/:mfaId`;
export const ASSIGN_MFA = `${MFA_BASE_URL}/:mfaId/applications/:appId`;

// LDAP Management
const LDAP_BASE_URL = `${API_PATH}/LDAPs`;
export const GET_LDAPS = `${LDAP_BASE_URL}/`;
export const GET_LDAP = `${LDAP_BASE_URL}/:ldapId`;
export const UPDATE_LDAP = `${LDAP_BASE_URL}/:ldapId`;
export const DELETE_LDAP = `${LDAP_BASE_URL}/:ldapId`;
export const ADD_LDAP_MAPPING = `${LDAP_BASE_URL}/:ldapId/IDPs/:idpId`;
export const DELETE_LDAP_MAPPING = `${LDAP_BASE_URL}/:ldapId/IDPs/:idpId`;
export const UPDATE_LDAP_MAPPING = `${LDAP_BASE_URL}/mappings/:mappingId`;
export const GET_LDAP_MAPPING = `${LDAP_BASE_URL}/:ldapId/mappings`;
export const GET_TEST_CASE = `${LDAP_BASE_URL}/testcases/:testCaseId`;

// Overview Management
const OVERVIEW_BASE_URL = `${API_PATH}/overview`;
export const GET_META_INFO = `${OVERVIEW_BASE_URL}/metaInfo`;
export const GET_ACTIVITIES = `${OVERVIEW_BASE_URL}/activities/`;
export const GET_RESOURCE_USAGE = `${OVERVIEW_BASE_URL}/resource-usage`;
export const GET_AUDIT_LOGS = `${OVERVIEW_BASE_URL}/auditorLogs/`;
export const GET_AUDIT_LOG_ACTORS = `${OVERVIEW_BASE_URL}/auditorLogs/actors/`;
export const GET_ACCESS_BROKERS = `${OVERVIEW_BASE_URL}/abStatuses/`;

// Rule Management
const RULE_BASE_URL = `${API_PATH}/rules`;
export const GET_RULES = `${RULE_BASE_URL}/`;
export const GET_RULE_DETAIL = `${RULE_BASE_URL}/:ruleId`;
export const DELETE_RULE = `${RULE_BASE_URL}/:ruleId`;
export const UPDATE_RULE = `${RULE_BASE_URL}/:ruleId`;
export const DEL_APP_RULE = `${RULE_BASE_URL}/:ruleId/applications/:appId`;

// User Management
const USER_BASE_URL = `${API_PATH}/users`;
export const LOGOUT = `${USER_BASE_URL}/logout`;
export const UPDATE_USER_INFO_SELF = `${USER_BASE_URL}`;
export const GET_USER_LIST = `${USER_BASE_URL}/`;
export const GET_USER_INFO = `${USER_BASE_URL}`;
export const RESET_AUTHENTICATOR = `${USER_BASE_URL}/authentication`;
export const INVITE_USER = `${USER_BASE_URL}/invitation`;
export const ACTIVATE_USER = `${USER_BASE_URL}/activation`;
export const DELETE_USER = `${USER_BASE_URL}/:userId`;
export const SEARCH_USER = `${USER_BASE_URL}/search`;
export const UPDATE_USER = `${USER_BASE_URL}/:userId`; // update any user if you are a SUPER ADMIN USER under same organization
export const INVALID_ALL_REFRESH_TOKENS = `${USER_BASE_URL}/refreshTokens`;
export const UPDATE_USER_ROLE = `${USER_BASE_URL}/:userId/role`;
export const CHANGE_USER_ORG = `${USER_BASE_URL}/:userId/organization`;

// MFA User Management
const MFA_USER_BASE_URL = `${API_PATH}/mfa-users`;
export const GET_OTP_USER_LIST = `${MFA_USER_BASE_URL}/`;
export const CREATE_OTP_USER = `${MFA_USER_BASE_URL}`;
export const UPDATE_OTP_USER = `${MFA_USER_BASE_URL}/:userId`;
export const DELETE_OTP_USER = `${MFA_USER_BASE_URL}/:userId`;
export const IMPORT_OTP_USERS = `${MFA_USER_BASE_URL}/batch`;
export const ASSIGN_MFA_USER_TO_GROUP = `${MFA_USER_BASE_URL}/:userId/to/mfa-groups/:groupId`;
export const DE_ASSIGN_MFA_USER_FROM_GROUP = `${MFA_USER_BASE_URL}/:userId/to/mfa-groups/:groupId`;
export const REGISTER_MFA_USER_AUTHENTICATOR = `${MFA_USER_BASE_URL}/:userId/authenticator/register`;
export const RESET_MFA_USER_AUTHENTICATOR = `${MFA_USER_BASE_URL}/:userId/authenticator/reset`;

// MFA Group Management
const MFA_GROUP_BASE_URL = `${API_PATH}/mfa-groups`;
export const GET_OTP_GROUPS = `${MFA_GROUP_BASE_URL}/`;
export const CREATE_OTP_GROUP = `${MFA_GROUP_BASE_URL}`;
export const UPDATE_OTP_GROUP = `${MFA_GROUP_BASE_URL}/:groupId`;
export const DELETE_OTP_GROUP = `${MFA_GROUP_BASE_URL}/:groupId`;
export const GET_OTP_GROUP_DETAIL = `${MFA_GROUP_BASE_URL}/:groupId`;
export const GET_OTP_GROUP_USERS = `${MFA_GROUP_BASE_URL}/:groupId/mfa-users/`;

// Organization Management
const ORGANIZATION_BASE_URL = `${API_PATH}/organization`;
export const CREATE_ORGANIZATION = `${ORGANIZATION_BASE_URL}`;
export const GET_ORGANIZATION = `${ORGANIZATION_BASE_URL}`;
export const GET_ALL_ORGANIZATIONS = `${ORGANIZATION_BASE_URL}/all`;
export const UPDATE_ORGANIZATION = `${ORGANIZATION_BASE_URL}`;

// Setting Management
const SETTING_BASE_URL = `${API_PATH}/settings`;
export const GET_SETTINGS = `${SETTING_BASE_URL}`;
export const UPDATE_SETTINGS = `${SETTING_BASE_URL}`;
export const UPDATE_MAX_USAGE = `${SETTING_BASE_URL}/maximum`;
export const SEARCH_SSO_CONFIG = `${SETTING_BASE_URL}/`;

// Authorizers Management
const AUTHORIZER_BASE_URL = `${API_PATH}/authorizers`;
export const GET_AUTHORIZERS = `${AUTHORIZER_BASE_URL}/`;
export const CREATE_AUTHORIZER = `${AUTHORIZER_BASE_URL}`;
export const UPDATE_AUTHORIZER = `${AUTHORIZER_BASE_URL}/:authorizerId`;
export const DELETE_AUTHORIZER = `${AUTHORIZER_BASE_URL}/:authorizerId`;
export const GET_AUTHORIZER_DETAIL = `${AUTHORIZER_BASE_URL}/:authorizerId`;

