import { message, Modal } from 'antd';
import { action, observable, runInAction } from 'mobx';
import { ENDING_SLASH_OR_BACK_SLASH_PATTERN } from '../common/constant/pattern.constants';
import { DEFAULT_PAGE_SIZE, IdPTypeEnum, SSLModeEnum, UpstreamSwitchEnum } from '../common/constants';
import { removeEmpty, removeEmptyValueInObject } from '../common/util';
import { applicationMessage } from '../i18n/i18n';
import * as ApplicationMgmtRes from '../services/model/application';
import { ApplicationFormInputs } from '../services/model/application';
import { AisIdpFormInputs } from '../services/model/idp';
import Services from '../services/services';
import ErrorMs from '../types/ErrorMs';
import { sslStore } from './sslStore';
import { userStore } from './userStore';


const { confirm } = Modal;


export class ApplicationsStore {
  private static instance: ApplicationsStore;

  public static getInstance(): ApplicationsStore {
    if (!ApplicationsStore.instance) {
      ApplicationsStore.instance = new ApplicationsStore();
    }

    return ApplicationsStore.instance;
  }


  @observable currentApplication: Partial<ApplicationMgmtRes.Application> = {};
  @observable applicationListData: Partial<ApplicationMgmtRes.ApplicationsRes> = {};
  @observable userProfileLoading = false;
  

  async init(deploymentId?: number) {
    await this.getApplicationList(0, undefined, deploymentId);
  }

  removeEndingBackSlash = (payload: ApplicationMgmtRes.ApplicationFormInputs | ApplicationMgmtRes.Application): any => {
    if (!ENDING_SLASH_OR_BACK_SLASH_PATTERN.test(payload.publicDomain)) {
      payload.publicDomain = payload.publicDomain.slice(0, payload.publicDomain.length - 1);
    }
    payload.upstreams = payload.upstreams?.map((upstream: string) => {
      if (!ENDING_SLASH_OR_BACK_SLASH_PATTERN.test(upstream)) {
        upstream = upstream.slice(0, upstream.length - 1);
      }
      return upstream;
    });
    return payload;
  }

  @action
  async createApplication(payload: ApplicationMgmtRes.CreateApplicationParams, successCallback?: Function) {
    payload = this.removeEndingBackSlash(payload);
    payload.upstreams = removeEmpty(payload.upstreams);
    try {
      const res = await Services.createApplication(payload);
      if (payload.enableSSL && payload.enableSSL[0] === 'ENABLED_SSL') {
        await sslStore.updateSSL({
          isSSL: true,
          mode: SSLModeEnum.SELF_SIGNED
        }, res.data.id);
      }
      message.success(applicationMessage.createSuccess);
      if (successCallback) {
        successCallback();
      }
      return res;
    } catch (e) {
      if (e instanceof ErrorMs) {
        message.error(e.message);
      } else if (e instanceof Error) {
        message.error(e.message);
      }
    }
  }

  @action
  async getApplicationDetailByExpand(app: ApplicationMgmtRes.Application) {
    const accessRulesRes = await Services.getAppRules({ appId: app.id, ruleType: 'ACCESS' });
    const redirectRulesRes = await Services.getAppRules({ appId: app.id, ruleType: 'REDIRECT' });
    const tmp: Array<string> = [];
    accessRulesRes.data.map((item) => {
      tmp.push(item.name);
    });
    redirectRulesRes.data.map((item) => {
      tmp.push(item.name);
    });
    app.rules = tmp.toString();
    await Services.getIdPsOfApplication({ appId: app.id, type: IdPTypeEnum.GENERAL }).then((res) => {
      const tmp: Array<string> = [];
      res.data && res.data.map((item) => {
        tmp.push(item.name);
      });
      app.idpNames = tmp.toString();
    });
  }

  @action
  async getApplicationList(page: number, pageSize: number = DEFAULT_PAGE_SIZE, deploymentId?: number) {
    if (deploymentId) {
      await Services.getApplicationsOfDeployment({
        page,
        size: pageSize,
        deploymentId,
      }).then((res) => {
        runInAction(() => {
          this.applicationListData = res;
        });
      }).catch((e) => {
        message.error(e.message);
      });
    } else {
      await Services.getApplicationsList({
        page,
        size: pageSize,
      }).then((res) => {
        runInAction(() => {
          this.applicationListData = res;
        });
      }).catch((e) => {
        message.error(e.message);
      });
    }
  }

  @action
  async getApplicationDetail(appId: string) {
    try {
      const applicationDetailRes = await Services.getApplicationDetail({ appId });
      const advancedSettingRes = await this.getAdvancedSetting(appId);
      const idpsRes = await Services.getIdPsOfApplication({ appId, page: 0 });
      this.currentApplication = {
        ...applicationDetailRes.data,
        upstreams: applicationDetailRes.data.upstreams || [''],
        upstreamSwitch: applicationDetailRes.data.upstreamSwitch ? applicationDetailRes.data.upstreamSwitch : UpstreamSwitchEnum.CUSTOM,
        ...advancedSettingRes.data,
        idps: idpsRes.data
      };
      return applicationDetailRes;
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message);
      }
    }
  }

  @action
  deleteApplication(appId: string, onSuccess?: Function) {
    confirm({
      title: 'Do you want to delete this application?',
      okButtonProps: { danger: true },
      okText: 'Delete',
      onOk: async () => {
        try {
          await Services.deleteApplication({ appId });
          await userStore.getUsageInfo();
          message.success(applicationMessage.deleteSuccess);
          if (onSuccess) {
            onSuccess();
          }
        } catch (e) {
          if (e instanceof Error) {
            message.error(e.message);
          }
        };
      },
    });
  }

  @action
  async updateApplication(applicationData: ApplicationFormInputs, appId: string, successCallback?: Function, failureCallback?: Function) {
    let payload = { ...applicationData, appId };
    payload = this.removeEndingBackSlash(payload);
    // payload.upstreams = removeEmpty(payload.upstreams);
    await Services.updateApplication(payload).then((res) => {
      Object.assign(this.currentApplication, removeEmptyValueInObject(payload));
      message.success(applicationMessage.updateSuccess);
      if (successCallback) {
        successCallback();
      }
    }).catch((e) => {
      message.error(e.message);
      if (failureCallback) {
        failureCallback();
      }
    });
  }

  async updateAisIdp(data: AisIdpFormInputs, appId: string) {
    try {
      await Services.updateAisIdp({ ...this.currentApplication, ...data, appId });
      message.success(applicationMessage.updateSuccess);
    } catch (err) {
      if (err instanceof ErrorMs) {
        message.error(err.message);
      }
    }
  }
    

  async updateTemplate(payload: ApplicationMgmtRes.ApplicationTemplate, appId: string) {
    await Services.updateApplicationTemplate({ ...payload, appId }).then(() => {
      message.success(applicationMessage.template.updateSuccess);
    }).catch((e) => {
      message.error(e.message);
    });
  }

  getTemplate(appId: string) {
    return Services.getApplicationTemplate({ appId });
  }

  async updateAdvancedSetting(appId: string, payload: ApplicationMgmtRes.AdvancedSetting) {
    try {
      await Services.updateAdvancedSetting({ ...payload, appId });
      message.success(applicationMessage.advancedSetting.updateSuccess);
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message);
      }
    } 
  }

  getAdvancedSetting(appId: string) {
    return Services.getAdvancedSetting({ appId });
  }

  updateKerberos(appId: string, payload: ApplicationMgmtRes.Kerberos) {
    return Services.updateAppKerberos({ ...payload, appId });
  }

  async getProfileSelect(appId: string) {
    try {
      const res = await Services.getApplicationProfileSelect({ appId });
      return res;
    } catch (e) {
      throw e;
    }
  }

  updateProfileSelect(appId: string, payload: ApplicationMgmtRes.ProfileSelectFormInputs) {
    return Services.updateApplicationProfileSelect({ ...payload, appId });
  }

  async getKerberos(appId: string) {
    const res = await Services.getApplicationKerberos({ appId });
    if (res.data && res.data.keytab) {
      res.data.keytab = {
        name: res.data.keytabFileName,
        size: res.data.keytab.length,
        uid: 0,
        status: 'done',
      };
    }
    return res;
  }

  async copy(appId: string, data: {deploymentId: string; name: string; publicDomain: string}, successCallback: Function) {
    try {
      await Services.copyApplication({ ...data, appId });
      message.success(applicationMessage.copySuccess);
      successCallback();
    } catch (e) {
      if (e instanceof Error) {
        message.error(e.message);
      }
    }
  }

  async enableApplication(appId: string) {
    await Services.enableApplication({ appId });
  }

  async disableApplication(appId: string) {
    await Services.disableApplication({ appId });
  }
}

export const applicationsStore = ApplicationsStore.getInstance();
